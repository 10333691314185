var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.srcDate.length<10 && _vm.nullDateText.length>0)?_c('span',[_vm._v("\n    "+_vm._s(_vm.nullDateText)+"\n    "),(_vm.newLine)?_c('br'):_vm._e()]):(_vm.srcDate.length>=10 && _vm.displayType=='calendar-month')?_c('span',[_vm._v("\n    "+_vm._s(new Date(_vm.srcDate + "+00:00").toLocaleDateString(
        _vm.dateFormat, {
        month: "short",
        timeZone: "UTC"
      }))+"\n    "),(_vm.newLine)?_c('br'):_vm._e()]):(_vm.srcDate.length>=10 && _vm.displayType=='calendar-date')?_c('span',[_vm._v("\n    "+_vm._s(new Date(_vm.srcDate + "+00:00").toLocaleDateString(
        _vm.dateFormat, {
        day: "numeric",
        timeZone: "UTC"
      }))+"\n    "),(_vm.newLine)?_c('br'):_vm._e()]):(_vm.srcDate.length>=10 && _vm.displayType=='YMD')?_c('span',[_vm._v("\n    "+_vm._s(new Date(_vm.srcDate + "+00:00").toLocaleDateString(
        _vm.dateFormat,
        _vm.srcDate && _vm.srcDate.slice(11, 19) !== "00:00:00" && _vm.srcDate.slice(11, 19) !== "23:59:59"
          ? {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "UTC"
            }
          : {
              year: "numeric",
              month: "short",
              day: "numeric",
              timeZone: "UTC"
            }
      ))+_vm._s(_vm.suffix)+"\n    "),(_vm.newLine)?_c('br'):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }