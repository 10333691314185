//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         getStateRules,
         getNewVfaDate } from '~/utils/butterUtils'
import { STATE_ISO_CODE,
         getVFAParameters } from '~/utils/VFAParameters'

export default {
  props: [ 'dict' ],
  data () {
    return {
      selectedState: null
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    statesSortedLocalized () {
      return STATE_ISO_CODE
        .map(x => ({iso: x, name: this.getDictFLJ( `states.${x}`, this.dict)}))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    stateRules () {
      let scr1 = this.butterStateVotingRules.find(x => x.stateid.toLowerCase().slice(0, 2) === this.selectedState.toLowerCase())
      if (!scr1) {
        return {'iso': this.$route.params.stage}
      }
      return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE () {
      return this.VFAParameters.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE
    },
  },
  methods: {
    goBegin (selectedState) {
      let lsObj = JSON.stringify(Object.assign({}, this.$store.state.votingState, {'selectedState': this.selectedState}, {'expiryTime': getNewVfaDate().getTime()+this.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE*1000}))
      localStorage.setItem('localstoragevotingstate', lsObj)
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.commit('saveSelectedState', selectedState)
      /**
       * 2024-08-12 John Yee
       * per conversation with Heidi Burch
       * Heidi sees no need to display the votertype.vue page because the information collected
       * is not used in a significant manner later in the website.
       * 
       * I am commenting out the if-then-else rather than removing it pending a more detailed
       * review of VFA in 2025.  The replacement code is simply the else clause's code.
       */
      /*
      if (this.stateRules.militaryseparate || (this.stateRules.votertypesused && this.stateRules.votertypesused!=='not-used')) {
        this.goStart('votertype', selectedState)
      } else {
        this.goStart('elections-state', selectedState)
      }
      */
      this.goStart('elections-state', selectedState)
    },
    goStart (pageName, selectedState) {
      this.$router.push( this.localePath({ name: pageName, params: { state: selectedState }}) )
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  }
}
